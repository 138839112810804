import React, { useState, useEffect, useContext } from "react";
import HOST from "../host";
import { ToastContainer, toast } from 'react-toastify';

import Modal from 'react-modal';
import { GlobalContext } from "../../App";
import { logToServer } from "../logger";


export default function TransTable() {
    const [transactions, setTransactions] = useState([]);
    const [credits, setCredits] = useState([]);
    const { userInfo, getCsrfToken } = useContext(GlobalContext);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;
    const [totalPages, setTotalPages] = useState(1);
    const [activeTab, setActiveTab] = useState("Payments"); // Default tab is Payments

    const dict = {
        0: "Pending",
        1: "Success",
        2: "Failed"
    }
    const debitDict={
        0:"Admin",
        1:"Self"
    }
    const typeDict={
        0:"Credit",
        1:"Debit"
    }

    useEffect(() => {
        if (activeTab === "Payments") {
            fetchTransactions(userInfo.id);
        } else {
            fetchCreditTransactions(userInfo.id);
        }
    }, [activeTab, userInfo.id]);

    useEffect(() => {
        if (activeTab === "Payments") {
            setTotalPages(Math.ceil(transactions.length / itemsPerPage));
        } else {
            setTotalPages(Math.ceil(credits.length / itemsPerPage));
        }
    }, [transactions, credits]);

    const fetchTransactions = () => {
        fetch(`${HOST}/get-transaction/${userInfo.id}`,
            {
                credentials: 'include',
            })
            .then((response) => response.json())
            .then((data) => {
                console.log(data)
                setTransactions(data.data);
            })
            .catch((error) => {
                console.error("Error fetching transactions:", error);
                logToServer('error', 'Error fetching transactions');
            });
    };

    const fetchCreditTransactions = () => {
        fetch(`${HOST}/get-credit-transaction/${userInfo.id}`,
            {
                credentials: 'include',
            })
            .then((response) => response.json())
            .then((data) => {
                console.log(data)
                setCredits(data.data);
            })
            .catch((error) => {
                console.error("Error fetching credit transactions:", error);
                logToServer('error', 'Error fetching credit transactions');
            });
    };

    const paginate = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const currentItems = activeTab === "Payments"
        ? transactions.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage)
        : credits.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

    return (
        <div style={{ display: "flex", flexDirection: "column", height: 'calc(85vh - 10px)', marginBottom: '10px' }}>
            <div>
            {/* Tabs for Payments and Credits */}
            <div className="tabs-container">
                <span className={`tab ${activeTab === "Payments" ? 'active' : ''}`} onClick={() => setActiveTab("Payments")}>
                    Payments
                </span>
                <span className={`tab ${activeTab === "Credits" ? 'active' : ''}`} onClick={() => setActiveTab("Credits")}>
                    Credits
                </span>
            </div>
            </div>
            <div style={{ display: 'flex', flexDirection: "column", width: '100%', flex: 1, height: '74vh' }}>
            <div style={{ display: 'flex', flexDirection: 'column', width: '100%', overflowY: 'scroll' }}>
            <div className="table-container" style={{ marginRight: '2%', marginLeft: '2%' }}>
                <div style={{ height: '69vh', overflowY: 'scroll' }}>
                    <table className="custom-table1">
                        <thead style={{ position: 'sticky', top: '0', zIndex: '1', backgroundColor: '#f5f5f5' }}>
                            <tr>
                                {activeTab === "Payments" && (
                                    <>
                                        {userInfo.is_superuser && (<th>Organization</th>)}
                                        {(userInfo.is_admin || userInfo.is_superuser) && (<th>User ID</th>)}
                                        {(userInfo.is_admin || userInfo.is_superuser) && (<th>Username</th>)}
                                        <th>Amount</th>
                                        <th>Status</th>
                                        <th>Method</th>
                                        <th>UTR</th>
                                        <th>Time</th>
                                    </>
                                )}

                                {activeTab === "Credits" && (
                                    <>
                                        {userInfo.is_superuser && (<th>Organization</th>)}
                                        {(userInfo.is_admin || userInfo.is_superuser) && (<th>User ID</th>)}
                                        {(userInfo.is_admin || userInfo.is_superuser) && (<th>Username</th>)}
                                        <th>Amount</th>
                                        <th>Type</th>
                                        <th>Credited By</th>
                                        <th>Feature</th>
                                        <th>Time</th>
                                    </>
                                )}
                            </tr>
                        </thead>
                        <tbody>
                            {currentItems.map((transaction, index) => (
                                <tr key={index}>
                                    {userInfo.is_superuser && (<td>{transaction.org}</td>)}
                                    {(userInfo.is_admin || userInfo.is_superuser) && (<td>{transaction.memb_id}</td>)}
                                    {(userInfo.is_admin || userInfo.is_superuser) && (<td>{transaction.memb_name}</td>)}
                                    <td>{transaction.amount}</td>
                                    
                                    {activeTab === "Payments" && (
                                        <>
                                            <td>{dict[transaction.status]}</td>
                                            <td>{transaction.method}</td>
                                            <td>{transaction.utr}</td>
                                        </>
                                    )}
                                    
                                    {activeTab === "Credits" && (
                                        <>
                                            <td>{typeDict[transaction.type]}</td>
                                            {transaction.type ===0 ? (<td>{debitDict[transaction.debit_type]}</td>):(<td></td>)}
                                            {transaction.type ===1 ? (<td>{transaction.feature}</td>):(<td></td>)}
                                            
                                        </>
                                    )}

                                    <td>
                                        {new Date(transaction.created).toLocaleString('en-US', {
                                            year: 'numeric',
                                            month: 'long',
                                            day: 'numeric',
                                            hour: '2-digit',
                                            minute: '2-digit',
                                            second: '2-digit',
                                            hour12: true
                                        })}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>

            {/* Pagination */}
            <div className="pagination">
                <button onClick={() => paginate(currentPage - 1)} disabled={currentPage === 1}>&lt;</button>
                {currentPage > 3 && <button onClick={() => paginate(1)}>1</button>}
                {currentPage > 4 && <span>...</span>}
                {currentPage > 2 && <button onClick={() => paginate(currentPage - 2)}>{currentPage - 2}</button>}
                {currentPage > 1 && <button onClick={() => paginate(currentPage - 1)}>{currentPage - 1}</button>}
                <button className="current-page">{currentPage}</button>
                {currentPage < totalPages && <button onClick={() => paginate(currentPage + 1)}>{currentPage + 1}</button>}
                {currentPage < totalPages - 1 && <button onClick={() => paginate(currentPage + 2)}>{currentPage + 2}</button>}
                {currentPage < totalPages - 3 && <span>...</span>}
                {currentPage < totalPages - 2 && <button onClick={() => paginate(totalPages)}>{totalPages}</button>}
                <button onClick={() => paginate(currentPage + 1)} disabled={currentPage === totalPages}>&gt;</button>
            </div>
            </div>
            </div>
        </div>
    );
}
