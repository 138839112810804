import React, { useState, useEffect, useContext } from 'react';
import HOST from '../host';
import { SideBarContext } from '../Main/sidebar';
import { GlobalContext } from '../../App';
import L from 'leaflet';
import { bbox } from '@turf/turf';
import ReactDOM from 'react-dom';



const Popup = ({ children, onClose }) => {
  return ReactDOM.createPortal(
    <div className="popup" style={{
      position: 'absolute',
      top: '40%',
      left: '350px',
      zIndex: 5000,
      width: '400px',
      maxHeight: '50%',
      overflowY: 'auto',
      backgroundColor: '#fff',
      padding: '10px',
      border: '1px solid #ccc',
      boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
      borderRadius: '8px',
    }}>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <h4 style={{ margin: 0 }}>Image Results</h4>
        <button onClick={onClose} style={{
          background: 'transparent',
          border: 'none',
          fontSize: '18px',
          cursor: 'pointer',
          color: '#ff0000',
        }}>×</button>
      </div>
      <div style={{ marginTop: '10px' }}>{children}</div>
    </div>,
    document.body
  );
};



function UP42() {
  const [product, setProduct] = useState([]);
  const [activeID, setActiveID] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [images, setImages] = useState([]);
  const [filters, setFilters] = useState({
    dateStart: '',
    dateEnd: '',
    maxCloud: 20,
  });
  const { lastRect, drawnItems, map, layerControls } = useContext(GlobalContext);
  const { setloader } = useContext(SideBarContext);
  const [geometryLayer, setGeometryLayer] = useState(null);
  const [showPopup, setShowPopup] = useState(false); // New state for popup visibility
  const [filterType, setFilterType] = useState("OPTICAL");
  // Fetch collections from the /get-products route


  useEffect(() => {

    const today = new Date();
    const oneMonthAgo = new Date();
    oneMonthAgo.setMonth(today.getMonth() - 1);
    setFilters({
      ...filters,
      dateStart: oneMonthAgo.toISOString().split('T')[0],
      dateEnd: today.toISOString().split('T')[0],
    });
  }, []);



  const handleProductClick = (product) => {
    if (selectedProduct && selectedProduct.title === product.title) {
      return; // Do nothing if the same product is clicked again
    }
    setSelectedProduct(product)

  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters({
      ...filters,
      [name]: value,
    });
  };

  const handleSearch = async () => {


    if (!filters.dateStart) {
      alert('Please select a start date.');
      return;
    }

    let box = null;
    if (lastRect && drawnItems.hasLayer(lastRect)) {
      box = drawnItems.getLayer(lastRect).toGeoJSON();
    } else {
      alert('Please draw a box');
      return;
    }

    const { dateStart, dateEnd, maxCloud } = filters;

    try {
      setloader(true);
      const response = await fetch(`${HOST}/get-products`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          type: filterType,
          box,
          dates: [dateStart, dateEnd || dateStart],
          maxCloud,
        }),
      });
      const Response = await response.json();

      if (Response.length) {
        setProduct(Response);
        // setShowPopup(true); // Show the popup when images are found
      } else {
        alert("No Products Found");
        // setShowPopup(false); // Ensure popup is hidden if no images
      }

      setloader(false);
    } catch (error) {
      alert("No Products Found");
      setloader(false);
      // setShowPopup(false); // Ensure popup is hidden in case of error
    }
  };

  const handlePreviewClick = async (sceneId, geom, name) => {
    try {
      setloader(true);
      const response = await fetch(`${HOST}/get-preview`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          name: name,
          sceneId,
        }),
      });
      const previewPath = await response.json();
      let path = `https://stage.portal.vasundharaa.in/${previewPath.previewPath[0]}`;

      let box = bbox(geom);
      box = [[box[1], box[0]], [box[3], box[2]]];

      let layer = L.imageOverlay(path, box).addTo(map);
      layerControls.addOverlay(layer, `${sceneId}-preview`, true, box, false, false, false,);
      setloader(false);
    } catch (error) {
      setloader(false);
      console.error('Error fetching preview:', error);
    }
  };

  const handleEstimate = async (img, id) => {
    try {
      setloader(true);
      const response = await fetch(`${HOST}/get-estimate-up42`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          productId: id,
          sceneId: img.sceneId,
          geom: img.geometry,
        }),
      });

      const data = await response.json();
      const updatedSelectedImages = selectedProduct.images.map((image) =>
        image.sceneId === img.sceneId ? { ...image, estimate: data.estimate } : image
      );

      // Update the selected product state
      setSelectedProduct((prev) => ({
        ...prev,
        images: updatedSelectedImages,
      }));

      // Update product state
      const updatedProducts = product.map((prod) =>
        prod.product_id === id
          ? { ...prod, images: updatedSelectedImages } // Update images for the specific product
          : prod
      );

      setProduct(updatedProducts); // Update state with the new estimate
      setloader(false);
    } catch (error) {
      setloader(false);
      console.error('Error fetching estimate:', error);
    }
  };

  const handleMouseEnter = (geometry) => {
    if (geometryLayer) {
      map.removeLayer(geometryLayer);
    }
    const newLayer = L.geoJSON(geometry).addTo(map);
    setGeometryLayer(newLayer);
  };

  const handleMouseLeave = () => {
    if (geometryLayer) {
      map.removeLayer(geometryLayer);
      setGeometryLayer(null);
    }
  };

  const handleClosePopup = () => {
    setSelectedProduct(null); // Hide the popup by resetting selected product
  };
  return (
  <div>
  {/* Filters and search buttons */}
  <div className="hide-show-container">
    <h4 style={{ margin: '5px', fontSize: '20px', fontWeight: '600' }}>Filters:</h4>
    {/* Filter inputs */}
    <div className="mt-1 sidepanel-container">
      <label style={{  color: 'black' }} htmlFor="dateStart">Start Date:</label>
      <input
        type="date"
        id="dateStart"
        name="dateStart"
        value={filters.dateStart}
        onChange={handleFilterChange}
        className='form-control'
        aria-label="Select start date"
      />
    </div>
    <div  className="m-1 sidepanel-container">
      <label style={{  color: 'black' }} htmlFor="dateEnd">End Date:</label>
      <input
        type="date"
        id="dateEnd"
        name="dateEnd"
        value={filters.dateEnd}
        onChange={handleFilterChange}
        className='form-control'
        aria-label="Select end date"
      />
    </div>
    <div className="sidepanel-container">
      <label style={{  color: 'black' }} htmlFor="maxCloud">Max Cloud:</label>
      <input
        type="number"
        id="maxCloud"
        name="maxCloud"
        value={filters.maxCloud}
        onChange={handleFilterChange}

        min="0"
        max="100"
        className='form-control'
        aria-label="Set maximum cloud coverage"
      />
    </div>
    <div className="mt-1 sidepanel-container">
      <label style={{  color: 'black' }} htmlFor="filterType">Select Type</label>
      <select
        id="filterType"
        className='form-control'
        value={filterType}
        onChange={(e) => setFilterType(e.target.value)}
        aria-label="Select filter type"
      >
        <option value="OPTICAL">OPTICAL</option>
        <option value="SAR">SAR</option>
        <option value="ELEVATION">ELEVATION</option>
      </select>
    </div>
    <div>
      <button className="m-2 visual-btn" onClick={handleSearch}>Search</button>
    </div>
  </div>
  {/* Product List */}
  <div className="hide-show-container">
    <div className="sidepanel-container">
      <div className="product-list" style={{ maxHeight: '400px', overflowY: 'scroll', border: '1px solid #ccc', borderRadius: '4px', padding: '5px' }}>
        {product && product.length ? product.map((product) => (
          <div
            key={product.id}
            className="product-item"
            onClick={() => handleProductClick(product)}
            style={{
              border: '1px solid #ccc',
              padding: '10px',
              borderRadius: '4px',
              marginBottom: '5px',
              cursor: 'pointer',
              color: "black",
              backgroundColor: product.product_id === activeID ? '#E0F7FA' : '#fff',
            }}
          >
            <h3 style={{ margin: '0 0 5px', fontSize: '16px', color: '#007BFF' }}>{product.title}</h3>
            <p style={{ margin: '5px 0' }}>Resolution Class: {product.resolution_class}</p>
            <p style={{ margin: '5px 0' }}>Spatial Resolution: {product.value_min} {product.value_max && "-"} {product.value_max} meters</p>
          </div>
        )) : (
          <h3 style={{ margin: '0 0 5px', fontSize: '14px', color: 'black' }}>No Products</h3>
        )}
      </div>
    </div>
  </div>
  {/* Popup to display images */}
  {selectedProduct && selectedProduct.images.length > 0 && (
    <Popup onClose={handleClosePopup}>
      <div className="image-results">
        {selectedProduct.images.length > 0 ? (
          selectedProduct.images.map((img, idx) => (
            <div key={idx} className="image-item" onMouseEnter={() => handleMouseEnter(img.geometry)} onMouseLeave={handleMouseLeave}>
              <div className="image-info" style={{ border: '1px solid #eee', padding: '10px', borderRadius: '4px' }}>
                <p><strong>Date:</strong> {new Date(img.acquisitionDate).toLocaleDateString()}</p>
                <p><strong>Resolution:</strong> {img.resolution} m</p>
                <p><strong>Cloud Coverage:</strong> {img.cloudCoverage ? img.cloudCoverage + '%' : 'N/A'}</p>
                {("estimate" in img) && img.estimate !== "" && (
                  <p><strong>Order Estimate:</strong> {img.estimate} UP42 credits</p>
                )}
                <div className="button-group">
                  <button onClick={() => handlePreviewClick(img.sceneId, img.geometry, selectedProduct.name)} className="preview-btn">
                    Preview
                  </button>
                  {(!("estimate" in img) || img.estimate === "") ? (
                    <button onClick={() => handleEstimate(img, selectedProduct.product_id)} className="estimate-btn">
                      Get Estimate
                    </button>
                  ) : null}
                  {("estimate" in img && img.estimate !== "") ? (
                    <button disabled className="disabled-btn">Place Order</button>
                  ) : null}
                </div>
              </div>
            </div>
          ))
        ) : (
          <p>No images found for this product.</p>
        )}
      </div>
    </Popup>
  )}
</div>









 );
}

export default UP42;
