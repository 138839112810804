import React, { useState, useEffect, useContext } from "react";
import HOST from "../host";
import { NavLink, useNavigate } from "react-router-dom";
import { GlobalContext } from "../../App";
import files from "../static";
import "./ManageOrg.css";
import Modal from 'react-modal';
import { ToastContainer, toast } from 'react-toastify';
import { logToServer } from "../logger";

export default function UserProfile({ email, user }) {
    const [updateSuccess, setUpdateSuccess] = useState(false);
    const [memberId, setUserId] = useState([]);
    const [username, setName] = useState("");
    const [first_name, setFirstName] = useState("");
    const [last_name, setLastName] = useState("");
    const [number, setNumber] = useState("");
    const [member_email, setUserEmail] = useState("");
    const [isUpdate, setIsUpdate] = useState(false);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [selectedOrganizationId, setSelectedOrganizationId] = useState("");
    const { userInfo, setUserInfo, getCsrfToken,updateCredit } = useContext(GlobalContext);
    const [space, setSpace] = useState(null);
    const [reset, setReset] = useState(false);
    const [pass, setPass] = useState("");
    const [cPass, setCpass] = useState("");
    const [profilePicture, setProfilePicture] = useState(null);
    const [profilePictureUrl, setProfilePictureUrl] = useState("");
    const [orgModalIsOpen, setOrgModalIsOpen] = useState(false); // State to manage the organization modal
    const [organizationName, setOrganizationName] = useState(""); // State to manage the input organization name
    const [org_name, setorg_name] = useState("")
    const [org_web, setorg_web] = useState("")
    const [org_add, setorg_add] = useState("")
    const [org_plan, setorg_plan] = useState("")
    const [ProfilemodalIsOpen, setProfileModalIsOpen] = useState(false);
    const [planModal, setPlanModal] = useState(false)
    const [creditsModal,SetCreditsModal]=useState(false)
    const [Bcredits,setCredits]=useState(null)
    const planDict = { 0: "Individual", 1: "Basic", 2: "Advanced", 3: "Enterprise" };
    const navigate = useNavigate();


    const handleAddOrganization = async (e) => {
        e.preventDefault();
        try {
            const csrfToken = await getCsrfToken();

            const response = await fetch(`${HOST}/send_organization_request/${userInfo.id}/ `, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRFToken': csrfToken,
                },
                body: JSON.stringify({ email: organizationName }),
            });

            const data = await response.json();
            console.log(data);

            if (response.ok) {
                logToServer('info', 'Organization added successfully')
                toast.success("Organization added successfully");
                setOrgModalIsOpen(false);
            } else {
                toast.error(data.error || 'Failed to add organization');
            }
        } catch (error) {
            toast.error('An unexpected error occurred');
            logToServer('eroor', `${error}`)
            console.error('Error adding organization:', error);
        }
    };

    const convertToBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    };

    async function handleProfilePictureUpload(e) {
        e.preventDefault();
        try {
            const csrfToken = await getCsrfToken();
            const base64Image = await convertToBase64(profilePicture);

            const res = await fetch(`${HOST}/upload-profile-picture/${userInfo.id}`, {
                method: "POST",
                credentials: 'include',
                body: JSON.stringify({ profile_picture: base64Image }),
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRFToken': csrfToken,
                },
            });

            if (res.ok) {
                toast.success("Profile picture uploaded successfully");
                // Refresh profile picture after successful upload
                logToServer('info', 'Profile picture uploaded successfully')
                fetchProfilePicture();
                setModalIsOpen(false);
            } else {
                const errorData = await res.json();
                throw new Error(errorData.error || "Failed to upload profile picture");
            }
        } catch (error) {
            logToServer('error', `${error}`)
            toast.error(error.message);
        }
    }

    const handleProfilePictureChange = (e) => {
        setProfilePicture(e.target.files[0]);
    };

    async function resetPass(e) {
        e.preventDefault();
        const email = sessionStorage.getItem('email');
        if (pass && cPass && pass !== "" && cPass !== "") {
            if (pass === cPass) {
                if (passwordRegex.test(pass) && pass.length >= 8) {
                    try {
                        const res = await fetch(`${HOST}/reset-password`, {
                            method: "POST",
                            body: JSON.stringify({ data: { email: userInfo.email_address, password: pass } }),
                        });

                        if (res.status === 400) {
                            alert("Password reset failed");
                            logToServer('error', 'Password reset failed')
                        } else if (res.status === 200) {
                            alert("Password Reset Successfully");
                            logToServer('info', 'Password Reset Successfully')
                            sessionStorage.removeItem('email');
                        }
                    }
                    catch (e) {
                        alert("Unexpected error occurred. Please try again");
                    }
                } else {
                    alert("Password should contain at least one uppercase letter, one lowercase letter, one digit, and be at least 8 characters long");
                }
            } else {
                alert("Password and Confirm Password don't match");
            }
        } else {
            alert("Please enter Password and Confirm Password");
        }
    }


    async function ChangePlan(event) {
        event.preventDefault();
        const planData = userInfo.org_name !== "global" ? {
            org_plan: org_plan
        } : {
            org_name: org_name,
            org_web: org_web,
            org_add: org_add,
            org_plan: org_plan
        }
        await fetch(`${HOST}/change-plan/${userInfo.id}`, {
            method: "POST",
            credentials: 'include',
            body: JSON.stringify(planData),
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
        })
            .then((response) => {
                if (response.ok) {


                    alert("Plan updated Successfully. Please Refresh to see the changes");
                    toast.success("Plan updated Successfully");
                    logToServer('info', 'Plan update successfully')
                    window.location.reload()
                    return;
                } else {
                    throw new Error("Failed to update Plan");
                }
            })
            .catch((error) => {
                logToServer('error', `${error}`)
            });
    }




    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d#@]{8,}$/;
    const handleEdit = () => {
        setName(userInfo.username);
        setFirstName(userInfo.first_name);
        setLastName(userInfo.last_name);
        setUserEmail(userInfo.email_address);
        setNumber(userInfo.number);
        setUserId(userInfo.id);
        setIsUpdate(true);
        setModalIsOpen(true);
    };

    const handleUpdateUser = async (event, memberId) => {
        event.preventDefault();
        const updatedUserData = {
            username: username,
            first_name: first_name,
            last_name: last_name,
            email: member_email,
            number: number
        };

        await fetch(`${HOST}/update-member/${memberId}`, {
            method: "POST",
            credentials: 'include',
            body: JSON.stringify(updatedUserData),
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'X-CSRFToken': await getCsrfToken(),
            },
        })
            .then((response) => {
                if (response.ok) {
                    setUserInfo(prevUserInfo => ({
                        ...prevUserInfo,
                        username: username,
                        first_name: first_name,
                        last_name: last_name,
                        email: member_email,
                        number: number
                    }));
                    setIsUpdate(false);
                    alert("User updated Successfully. Please Refresh to see the changes");
                    toast.success("User updated Successfully");
                    logToServer('info', 'User update successfully')
                    navigate("/user-console");
                    return;
                } else {
                    throw new Error("Failed to update member");
                }
            })
            .catch((error) => {
                logToServer('error', `${error}`)
            });
    };

    async function fetchSpace() {
        try {
            const res = await fetch(`${HOST}/fetch-space/${userInfo.id}`);
            const data = await res.json();
            if (res.status === 400) {
                alert("Unexpected Error Occured. Please try again");
                navigate("/user-console");
            } else if (res.status === 200) {
                setSpace(data.space);
            }
        } catch (e) {
            alert("Unexpected Error Occured. Please try again");
            navigate("/user-console");
        }
    }



    const fetchProfilePicture = async () => {
        try {
            const res = await fetch(`${HOST}/get-profile-picture/${userInfo.id}`);
            if (res.ok) {
                const data = await res.json();
                // Decode base64 string received from backend
                const decodedImage = `data:image/jpeg;base64,${data.profile_pic_base64}`;
                setProfilePictureUrl(decodedImage);
                logToServer('info', 'fetch Profile picture successfully')
            } else {
                throw new Error("Failed to fetch profile picture");
            }
        } catch (error) {
            logToServer('error', `${error}`)
            // Handle error, e.g., show a default profile picture
        }
    };
    async function makePayment(event) {
        event.preventDefault();
        if (Bcredits && Bcredits!="") {
            try {
                const response = await fetch(`${HOST}/payment-url/${Bcredits}/${userInfo.id}`, {
                    method: 'POST',
                });
                const data = await response.json();
                console.log(data)
                if (data.url) {
                    console.log(data.url);
                    window.location.href = data.url; // Redirect the user to the payment URL
                }
            } catch (error) {
                console.error('Error sending message:', error);
            }
        } else {
            alert("Please enter the Amount of Credits");
        }
    }
    async function AddCredit(e) {
        e.preventDefault()
        const coupon = window.prompt("Enter Coupon");

    // Check if the entered password matches the required one
    if (!coupon) {
      alert("Please enter Coupon");
      return; // Stop execution if the password is incorrect
    }
        try {
          const response = await fetch(`${HOST}/add-credit-coupon/${userInfo.id}/${coupon}`, {
            method: "GET",
            credentials: "include"
          });
          if (response.ok) {

            alert(`Credits added Successfully `)
            updateCredit()
          } else {
            alert("Credits not added Successfully")
          }
        } catch (error) {
          alert("Credits not sent Successfully")

        }
        
    }
    useEffect(() => {
        fetchSpace()
        fetchProfilePicture(); // Fetch profile picture when component mounts
    }, []);

    return (
        <>
            <div className="table-container" style={{ maxHeight: 'calc(100vh - 200px)', overflow: 'hidden', overflowY: 'scroll', boxShadow: '1px 5px 10px 8px #bbbbbb' }}>
                <table>
                    <thead>
                        <tr>
                            <th className="th">
                                <img
                                    src={profilePictureUrl || `${process.env.PUBLIC_URL}/${files}userprofile.png`}
                                    style={{ width: "100px", maxHeight: "100px", borderRadius: "50%" }}
                                    onClick={() => setProfileModalIsOpen(true)}
                                />
                            </th>
                        </tr>
                        <tr>
                            <th className="th">Username :</th>
                            <td>{userInfo.username}</td>
                        </tr>
                        <tr>
                            <th className="th">First Name :</th>
                            <td>{userInfo.first_name}</td>
                        </tr>
                        <tr>
                            <th className="th">Last Name :</th>
                            <td>{userInfo.last_name}</td>
                        </tr>
                        <tr>
                            <th className="th">Email :</th>
                            <td>{userInfo.email_address}</td>
                        </tr>
                        <tr>
                            <th className="th">Contact No. :</th>
                            <td>{userInfo.number == '' ? userInfo.number : '-'}</td>
                        </tr>
                        {userInfo.org_name !== "global" && (
                            <tr>
                                <th className="th">Organization</th>
                                <td>{userInfo.org_name}</td>
                            </tr>
                        )}

                        {userInfo.org_name !== "global" && (
                            <tr>
                                <th className="th">Organziation Project</th>
                                <td>{userInfo.org_projects ? userInfo.org_projects : '-'}</td>
                            </tr>
                        )}
                        <tr>
                            <th className="th">Personal Project</th>
                            <td>{userInfo.user_projects ? userInfo.user_projects : '-'}</td>
                        </tr>
                        <tr>
                            <th className="th">Plan</th>
                            <td>{planDict[userInfo.plan]}</td>
                        </tr>
                        <tr>
                            <th className="th">Credits</th>
                            <td>{userInfo.credits}</td>
                        </tr>
                        <tr>
                            <th className="th">Space Used</th>
                            <td>
                                {space ? (space < 1024 ? space.toFixed(4) : (space / 1024).toFixed(1)) : 'Loading...'} {space ? (space < 1024 ? "MB" : "GB") : ''}
                                / 5 GB
                            </td>
                        </tr>
                        <th className="th" style={{ display: 'flex' }}>
                            <button type='button' className="mt-2 btn-add" onClick={handleEdit}>Update Profile</button>

                            {!userInfo.is_admin && userInfo.org_name === "global" && (

                                <button className="mt-2 btn-add" onClick={() => setOrgModalIsOpen(true)}>Add Organization</button>

                            )}
                            {(userInfo.is_admin || !userInfo.plan) && (
                                <button className="mt-2 btn-add" onClick={() => setPlanModal(true)}>Change Plan</button>
                            )}
                            {(userInfo.is_admin) && (
                                <button className="mt-2 btn-add" onClick={() => SetCreditsModal(true)}>Buy Credits</button>
                            )}
                             <button className="mt-2 btn-add" onClick={(e) => AddCredit(e)}>Add Credits</button>
                        </th>


                    </thead>
                </table>


                <Modal
                    isOpen={orgModalIsOpen}
                    onRequestClose={() => setOrgModalIsOpen(false)}
                    contentLabel="Add Organization Modal"
                    className='col-lg-3 col-sm-2 custom-modal'
                >
                    <form className="col-lg-10 col-md-6 col-sm-10 mt-2" onSubmit={handleAddOrganization}>
                        <div className="form-group">
                            <input
                                type="text"
                                value={organizationName}
                                onChange={(e) => setOrganizationName(e.target.value)}
                                placeholder="Enter Organization Email"
                                className="mt-2 form-control"
                                required
                            />
                        </div>
                        <button type="submit" className="mt-3 btn-add">Save</button>
                        <button type="button" className="mt-3 cancel" onClick={() => setOrgModalIsOpen(false)}>Close</button>
                    </form>
                </Modal>


                <Modal
                    isOpen={ProfilemodalIsOpen}
                    onRequestClose={() => setProfileModalIsOpen(false)}
                    contentLabel="Profile Picture Modal"
                    className='col-lg-3 col-sm-2 custom-modal'
                >
                    <div style={{ direction: 'rtl', marginRight: '10px' }}>
                        <button onClick={() => setProfileModalIsOpen(false)}>X</button>
                    </div>
                    <form className="col-lg-10 col-md-6 col-sm-10 mt-2" onSubmit={handleProfilePictureUpload}>
                        <div style={{ textAlign: 'center' }}>
                            <img
                                src={profilePictureUrl || `${process.env.PUBLIC_URL}/${files}userprofile.png`}
                                style={{ width: "200px", maxHeight: "200px", borderRadius: "50%" }}
                            />
                        </div>
                        <div style={{ textAlign: 'center' }}>
                            <button type="submit" className="mt-3 btn-add"
                                onClick={() => document.getElementById("profilePictureInputModal1").click()}
                            >Profile Upload</button>
                            <input
                                id="profilePictureInputModal1"
                                type="file"
                                accept="image/*"
                                onChange={handleProfilePictureChange}
                                className="mt-2 form-control"
                                style={{ display: "none" }}
                                required
                            />
                            <button type="submit" className="mt-3 btn-add">Save</button>
                        </div>
                    </form>



                </Modal>


                <Modal
                    isOpen={modalIsOpen}
                    onRequestClose={() => setModalIsOpen(false)}
                    contentLabel="Update Profile Modal"
                    className='col-lg-3 col-sm-2 custom-modal'
                >
                    <form className="col-lg-10 col-md-6 col-sm-10 mt-2">
                        <div className="form-group">
                            <input
                                type="text"
                                value={username}
                                onChange={(e) => setName(e.target.value)}
                                placeholder="Enter Username"
                                className="mt-2 form-control"
                            />
                        </div>
                        <div className="form-group">
                            <input
                                type="text"
                                value={first_name}
                                onChange={(e) => setFirstName(e.target.value)}
                                placeholder="Enter Firstname"
                                className="mt-2 form-control"
                            />
                        </div>
                        <div className="form-group">
                            <input
                                type="text"
                                value={last_name}
                                onChange={(e) => setLastName(e.target.value)}
                                placeholder="Enter Lastname"
                                className="mt-2 form-control"
                            />
                        </div>
                        <div className="form-group">
                            <input
                                type="text"
                                value={member_email}
                                onChange={(e) => setUserEmail(e.target.value)}
                                placeholder="Enter Email"
                                className="mt-2 form-control"
                            />
                        </div>
                        <div className="form-group">
                            <input
                                type="text"
                                value={number}
                                onChange={(e) => setNumber(e.target.value)}
                                placeholder="Kindly start with country code"
                                className="mt-2 form-control"
                                maxLength={13}
                            />
                        </div>
                    </form>
                    <button onClick={() => setReset(!reset)} className="mt-3 btn-add">Change Password</button>
                    {reset && (
                        <>
                            <form className="col-lg-10 col-md-6 col-sm-10 mt-2">
                                <input
                                    type="password"
                                    className="mt-2 form-control"
                                    value={pass}
                                    onChange={(e) => setPass(e.target.value)}
                                    placeholder="New password"
                                    required
                                />
                                <input
                                    type="password"
                                    className="mt-2 form-control"
                                    value={cPass}
                                    onChange={(e) => setCpass(e.target.value)}
                                    placeholder="Confirm password"
                                    required
                                />
                            </form>
                            <button
                                type="submit"
                                className="mt-3 btn-add"
                                onClick={(e) => resetPass(e)}
                            >Reset Password</button>
                        </>
                    )}
                    <button type="submit" className='mt-3 btn-add' style={{ marginLeft: '4%' }} onClick={(e) => handleUpdateUser(e, memberId)}>Update User</button>
                    <button type="button" className='mt-3 cancel' onClick={() => {
                        setModalIsOpen(false);
                        setIsUpdate(false);
                    }}>Close</button>
                </Modal>
                <Modal
                    isOpen={planModal}
                    onRequestClose={() => setPlanModal(false)}
                    contentLabel="Add Plan Modal"
                    className='col-lg-3 col-sm-2 custom-modal'
                >   {!userInfo.plan && (
                    <div className="col-lg-10 col-md-6 col-sm-10 mt-2">
                        <input
                            type="text"
                            value={org_name}
                            onChange={(e) => setorg_name(e.target.value)}
                            placeholder="Enter Organization Name"
                            className="mt-2 form-control"
                            required
                        />
                        <input
                            type="text"
                            value={org_web}
                            onChange={(e) => setorg_web(e.target.value)}
                            placeholder="Enter Organization Website"
                            className="mt-2 form-control"
                            required
                        />
                        <input
                            type="text"
                            value={org_add}
                            onChange={(e) => setorg_add(e.target.value)}
                            placeholder="Enter Organization Address"
                            className="mt-2 form-control"
                            required
                        />
                    </div>
                )}
                    <div style={{ marginTop: "20px" }}>
                        <select
                            name="org_plan"
                            value={org_plan}
                            onChange={(e) => setorg_plan(e.target.value)}
                            className="input"
                            required
                            style={{
                                fontSize: '12px',
                                width: '30%',
                                padding: '8px 12px',
                                border: '1px solid grey',
                                borderRadius: '4px', // Apply rounded corners uniformly

                                appearance: 'none',
                                WebkitAppearance: 'none',
                                MozAppearance: 'none',
                                outline: 'none',
                                cursor: 'pointer',
                                boxSizing: 'border-box', // Ensures padding doesn't affect width
                                marginRight: '8px', // Add some space to the right of the select
                            }}
                        >
                            <option value="" disabled>
                                Select Plan
                            </option>
                            <option value={1} disabled={userInfo.plan === 1} >
                                Basic
                            </option>
                            <option value={2} disabled={userInfo.plan === 2}>
                                Advanced
                            </option>
                            <option value={3} disabled={userInfo.plan === 3}>
                                Enterprise
                            </option>
                        </select>
                    </div>

                    <button type="submit" onClick={(e) => ChangePlan(e)} className="mt-3 btn-add">Save</button>
                    <button type="button" className="mt-3 cancel" onClick={() => setPlanModal(false)}>Close</button>

                </Modal>
                <Modal
                    isOpen={creditsModal}
                    onRequestClose={() => SetCreditsModal(false)}
                    contentLabel="Add Credits Modal"
                    className='col-lg-3 col-sm-2 custom-modal'
                >
                    <form className="col-lg-10 col-md-6 col-sm-10 mt-2" >
                        <div className="form-group">
                            <input
                                type="text"
                                value={Bcredits}
                                onChange={(e) => setCredits(e.target.value)}
                                placeholder="Enter Credits you want to buy"
                                className="mt-2 form-control"
                                required
                            />
                        </div>
                        <button type="submit" className="mt-3 btn-add" onClick={(e)=>makePayment(e)}>Buy</button>
                        <button type="button" className="mt-3 cancel" onClick={() => SetCreditsModal(false)}>Close</button>
                    </form>
                </Modal>
                <ToastContainer position="bottom-right" theme="colored" draggable={false} />
            </div>
        </>
    )
}
